



























import Vue from 'vue';
import CreateHotelClassificationRequestViewModel from '@/src/services/viewModel/resource/CreateHotelClassificationRequestViewModel';
import GuidLookupResourceViewModel from '@/src/services/viewModel/resource/GuidLookupResourceViewModel';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    accommodationTypeItems: [] as GuidLookupResourceViewModel[],
  }),
  computed: {
    innerValue: {
      get(): CreateHotelClassificationRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateHotelClassificationRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    const accommodationTypes = await this.$service.api.hotelClassifications.getAccommodationTypeItems();
    this.accommodationTypeItems = accommodationTypes.items;
  },
});
